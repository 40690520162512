import axios from '@/axios.js'

const state = () => ({
    kekerasan_bentuks: []
})

const mutations = {
    SET_KEKERASANBENTUKS (state, payload) {
      state.kekerasan_bentuks = payload
    },
    ADD_KEKERASANBENTUK (state, item) {
      state.kekerasan_bentuks.unshift(item)
    },
    UPDATE_KEKERASANBENTUK (state, kekerasan_bentuk) {
      const kekerasan_bentukIndex = state.kekerasan_bentuks.findIndex((p) => p.id === kekerasan_bentuk.id)
      Object.assign(state.kekerasan_bentuks[kekerasan_bentukIndex], kekerasan_bentuk)
    },
    REMOVE_KEKERASANBENTUK (state, itemId) {
      const ItemIndex = state.kekerasan_bentuks.findIndex((p) => p.id === itemId)
      state.kekerasan_bentuks.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchKekerasanBentuk ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/kekerasan_bentuk`)
        .then((response) => {
          commit('SET_KEKERASANBENTUKS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addKekerasanBentuk ({ commit }, kekerasan_bentuk ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', kekerasan_bentuk)
        .then((response) => {
          commit('ADD_KEKERASANBENTUK', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateKekerasanBentuk ({ commit }, kekerasan_bentuk) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${kekerasan_bentuk.id}`, kekerasan_bentuk)
        .then((response) => {
            commit('UPDATE_KEKERASANBENTUK', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormKekerasanBentuk ({ commit }, kekerasan_bentuk) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, kekerasan_bentuk)
        .then((response) => {
            commit('UPDATE_KEKERASANBENTUK', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeKekerasanBentuk ({ commit }, kekerasan_bentuk) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${kekerasan_bentuk.id}`)
        .then((response) => {
            commit('REMOVE_KEKERASANBENTUK', kekerasan_bentuk.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}